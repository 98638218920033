// List of all external URLs used in this project
export const URL_FACEBOOK = 'https://www.facebook.com/WagWalking';
export const URL_INSTAGRAM = 'https://instagram.com/wag';
export const URL_TIKTOK = 'https://www.tiktok.com/@wag';
export const URL_TWITTER = 'https://twitter.com/wagwalking';

export enum Urls {
  Investors = 'https://investors.wag.co'
}

export enum ServiceUrls {
  Boarding = 'https://wagwalking.com/dog-boarding',
  DropIn = 'https://wagwalking.com/drop-ins',
  Health = 'https://wagwalking.com/wag-health',
  Sitting = 'https://wagwalking.com/dog-sitting',
  Training = 'https://wagwalking.com/dog-training',
  Premium = 'https://wagwalking.com/wag-premium',
  Walking = 'https://wagwalking.com/dog-walking',
  WellnessPlans = 'https://wagwalking.com/wag-wellness/wellness-plans?utm_source=wag-co&utm_medium=web',
  PetInsurer = 'https://quote.petinsurer.com/quote/ref=wag?utm_source=wag-co&utm_medium=web',
  Petted = 'https://www.petted.com?utm_source=wag-co&utm_medium=web',
  Furmacy = 'https://furmacy.com',
  FurmacyVeterinarians = 'https://furmacy.com/veterinarians',
  DogFoodAdvisor = 'https://www.dogfoodadvisor.com',
  CatFoodAdvisor = 'https://www.catfoodadvisor.com',
  WoofWoofTV = 'https://woofwoof.tv/',
}

export enum ExecutiveUrls {
  Adam = 'https://www.linkedin.com/in/adamstorm/',
  Alec = 'https://www.linkedin.com/in/alecdavidian/',
  AlexanderJeremiah = 'https://www.linkedin.com/in/alexander-jeremiah/',
  Andrew = 'https://www.linkedin.com/in/andrewbreksa/',
  BenWilliamson = 'https://www.linkedin.com/in/ben-williamson-a66849149/',
  Beth = 'https://www.linkedin.com/in/beth-rescigno-631541/',
  Brian = 'https://www.linkedin.com/in/brian-yee-4a9b755/',
  Carli = 'https://www.linkedin.com/in/carli-auran-7a884834/',
  Carolina = 'https://www.linkedin.com/in/carolina-steczkowski-127177146/',
  Chas = 'https://www.linkedin.com/in/schley/',
  Chris = 'https://www.linkedin.com/in/christopher-rempel-978258/',
  David = 'https://www.linkedin.com/in/davidcane/',
  Dragana = 'https://www.linkedin.com/in/draganamijalkovic/',
  DrGeorgiaJeremiah = 'https://www.linkedin.com/in/gntt/',
  Dylan = 'https://www.linkedin.com/in/dallread/',
  Garrett = 'https://www.linkedin.com/in/garrettsmallwood/',
  Jimmy = 'https://www.linkedin.com/in/jdepetro/',
  Jocelyn = 'https://www.linkedin.com/in/jocelynmangan/',
  Joseph = 'https://www.linkedin.com/in/jschrick/',
  Kaitie = 'https://www.linkedin.com/in/kaitiedevries/',
  Karina = 'https://www.linkedin.com/in/karina-v-9a382411a/',
  Keith = 'https://www.linkedin.com/in/keith-mosley462/',
  Kimberly = 'https://www.linkedin.com/in/kimberlyblackwell/',
  Lindsey = 'https://www.linkedin.com/in/lindseycanfield/',
  MaryMiller = 'https://www.linkedin.com/in/mary-m-b347a16/',
  Mazi = 'https://www.linkedin.com/in/maziararjomand/',
  Melinda = 'https://www.linkedin.com/in/melindachelliah/',
  Nicholas = 'https://www.linkedin.com/in/nicholas-yu-2b4550a8/',
  Ollie = 'https://wagwalking.com',
  Patrick = 'https://www.linkedin.com/in/pmmccarthy/',
  Rachel = 'https://www.linkedin.com/in/rsprague/',
  Richard = 'https://www.linkedin.com/in/richard-dibona-5097054/',
  Roger = 'https://www.linkedin.com/in/rogerleevc/',
  Ryan = 'https://www.linkedin.com/in/ryanhogue/',
  Sheila = 'https://www.linkedin.com/in/sheilamarcelo/',
  Susan = 'https://www.linkedin.com/in/susankimfinanceexec/',
  Win = 'https://www.linkedin.com/in/winmin/',
  Jason = 'https://www.linkedin.com/in/jason-kim-cpa',
  KimberlyHollinger = 'https://www.linkedin.com/in/kimberly-r-hollinger-cpa-mba-65b32518a',
  Elisa = 'https://www.linkedin.com/in/emuntoni',
  Mariana = 'https://www.linkedin.com/in/mariana-freire-alves'
}

export enum VideoUrls {
  ProductOverview = 'https://www.youtube.com/embed/FQoVFhIEGEw',
}
